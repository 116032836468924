import React, {useState} from 'react';
import {ProductImage} from '@partssourceinc/react-ui-core';
import {Desktop, Phone, PhoneBig, Tablet} from 'responsive';
import AnonymousTrackingPanel from 'components/AnonymousTrackingPanel';
import {useHistory} from 'react-router';
import {getTrackingNumbersUrlsData, getDayMonth} from 'utility';
import {getAosLineItem} from '../aosUtility';
import 'less/anonymousOrderStatusCard.less';

const AnonymousOrderStatusCard = ({lineItem}) => {
    const {
        lineItemId,
        description,
        detailUrl,
        imagePath,
        imageAlt,
        quantity,
        returnQuantity,
        partNumber,
        status,
        condition,
        purchaseChoice,
        isContractProOption,
        warranty,
    } = lineItem;

    const historyLoc = useHistory();
    const isQuote = status === 'Quoted' || status === 'Requested';
    const li = getAosLineItem(lineItem);
    const [trackingPanel, setTrackingPanel] = useState({visible: false, lineItemId: 0});
    
    function showTrackingPanel() {
        setTrackingPanel({visible: true, lineItemId});
    }
    
    function hideTrackingPanel() {
        setTrackingPanel({visible: false, lineItemId: 0})
    }
    
    function sendLogViewHistory() {
        showTrackingPanel();
    }

    let urlsData = [];

    if (li.trackDetailResult) {
        urlsData = getTrackingNumbersUrlsData(li.trackDetailResult.trackingNumbers, lineItemId);
    }

    const renderServiceShipBox = () =>
        (<div className="shipping-info">
            <span className="detailBold">Estimated Service Date </span>
            <span className="detail">Estimated to be serviced TBD  </span>
        </div>);

    const renderPartShipBox = () =>
        (<div className="shipping-info">
            {li.showDD &&    
                    <span className="detailBold">{li.currentTrackingStatus} </span>}
            {li.showEDD &&    
                <span className="detailBold">Scheduled Delivery Date {getDayMonth(li.estimatedDeliveryDate) || 'Pending'} </span>}
            {li.showETA &&
                    <span className="detailBold">Estimated completion by {getDayMonth(li.repairCompletionDate)}</span>}
            {li.showESD && <span className="detailBold">Scheduled Delivery Date Pending </span>}
            {(li.showESD && li.estimatedShippedDate) &&                                            
                <span className="detail">Estimated to ship by {getDayMonth(li.estimatedShippedDate)} </span>}
            {(li.showESD && !li.estimatedShippedDate) && 
                <span className="detail">Estimated Ship Date pending</span>}
            <span className="detail">{li.shippingAddress} </span>
            {urlsData && urlsData.map((urlData, index) => {
                return <a target="_blank" rel="noreferrer noopener" href={`${urlData.url}`}># {urlData.number} </a>
            })}
        </div>);
    const renderOneColumn = () => 
        (<div className="card-view-mobile">
            <div className="part-card">
                <div className="part-image" onClick={() => detailUrl ? historyLoc.push(detailUrl) : null}>
                    {li.isService ? (<img src="/images/icn_repair.png" alt="Service Repair" style={{width: 60, marginLeft: 10}} />) :
                        (<ProductImage style={{width: '79px'}} url={imagePath} altText={imageAlt} />)}
                </div>
                <div className="part-info">
                    <span className="part-description trim-text-two-lines" onClick={() => detailUrl ? historyLoc.push(detailUrl) : null}>
                        {description}
                    </span>
                    <span className="detail">
                        Qty: <span style={{fontWeight: 'bold'}}>{quantity || returnQuantity}</span>
                    </span>
                    <span className="detail trim-text">
                        Item #: <span style={{fontWeight: 'bold'}}>{partNumber}</span>
                    </span>
                    {!isQuote && <span className="detail">
                        Condition: <span style={{fontWeight: 'bold'}}>{condition !== 'Repair' && condition} {purchaseChoice}</span>
                    </span>}
                    {!isContractProOption && !isQuote && <span className="detail trim-text">
                        Warranty: <span style={{fontWeight: 'bold'}}>{warranty}</span>
                    </span>}
                </div>
            </div>
            <div className="history-link" onClick={sendLogViewHistory}>VIEW HISTORY</div>
            {trackingPanel.visible && <AnonymousTrackingPanel
                show={true}
                status={status}
                lineItemId={trackingPanel.lineItemId}
                lineItemData = {li}
                onClose={hideTrackingPanel}
            />}
            {(li.isService) ? renderServiceShipBox() : renderPartShipBox()}
        </div>);
    const renderThreeColumns = () => 
        (<div className="card-view">
            <div className="part-image" onClick={() => detailUrl ? historyLoc.push(detailUrl) : null}>
                {li.isService ? (<img src="/images/icn_repair.png" alt="Service Repair" style={{width: 60, marginLeft: 60, marginTop: 10}} />) :
                    (<ProductImage style={{width: '79px'}} url={imagePath} altText={imageAlt} />)}
            </div>
            <div className="part-description trim-text-two-lines" onClick={() => detailUrl ? historyLoc.push(detailUrl) : null}>
                {description}
            </div>
            <span className="part-info">
                <span className="detail">
                    Qty: <span style={{fontWeight: 'bold'}}>{quantity || returnQuantity}</span>
                </span>
                <span className="detail trim-text">
                    Item #: <span style={{fontWeight: 'bold'}}>{partNumber}</span>
                </span>
                {!isQuote && <span className="detail">
                    Condition: <span style={{fontWeight: 'bold'}}>{condition !== 'Repair' && condition} {purchaseChoice}</span>
                </span>}
                {!isContractProOption && !isQuote && <span className="detail trim-text">
                    Warranty: <span style={{fontWeight: 'bold'}}>{warranty}</span>
                </span>}
            </span>
        </div>);

    return (
        <div>
            <Desktop>
                {renderThreeColumns()}
            </Desktop>
            <Tablet>
                {renderThreeColumns()}
            </Tablet>
            <PhoneBig>
                {renderOneColumn()}
            </PhoneBig>
            <Phone>
                {renderOneColumn()}
            </Phone>
        </div>
    );
}

export default AnonymousOrderStatusCard;
