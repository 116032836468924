import _ from 'lodash';
import {formatAMPM, formatDateToString} from 'utility';
import {months} from 'data/DatesData';

export function getAosLineItem(lineItem) {
    const events = lineItem.history || [];
    const trackDetailResult = lineItem.trackDetails || [];
    const isPart = lineItem.orderTypeId === 1;
    const isRepair = lineItem.orderTypeId === 4;
    const isService = lineItem.orderTypeId === 17;
    const isApprovalRequired = lineItem.isApproval || lineItem.isApproved;
    const isPORequired = lineItem.isAwaitingPoEnabled && events.find(e => e.statusId === 27);
    const dateQuoted = (!isService) ? events.find(e => e.statusId === 26) : events.find(e => e.description === 'Quote Available');
    const initiatedDate = lineItem.dateCreated;
    const orderPurchasedDate = (!isService) ? events.find(e => e.statusId === 7) : events.find(e => e.description === 'Purchased');
    const orderPlacedDate = events.find(e => e.statusId === 25);
    let sortedHistory = _.orderBy(events, ['timeStamp'], ['desc']);
    const sentRepair = (isRepair && lineItem.repairs) ? lineItem.repairs.find(e => e.shippmentLeg === 2 || e.shippmentLeg === 4) : null;
    const shippedRepair = (isRepair && lineItem.repairs) ? lineItem.repairs.find(e => e.statusDescription === 'Shipped' && 
        (e.shippmentLeg === 7 || e.shippmentLeg === 1 || e.shippmentLeg === 6)) : null;
    const showETA = (isRepair && lineItem.repairs && lineItem.repairs[0].statusDescription === 'Repairing');
    let trackingNumber = events && events.length && events.find(e => e.eventType === 'Tracking' && e.trackingNumber !== null);
    let trackingNumberPart = (isPart && lineItem.trackingNumber) ? (lineItem.trackingNumber) : null;
    let carrierPart = (isPart && lineItem.carrier) ? (lineItem.carrier) : null;
    let recentEvent = events && events.length && events[0];
    let currentTrackingStatus = null;
    let deliveredDate = null;
    let showDD = false;
    
    if (((isRepair && shippedRepair) || isPart) && (trackingNumber && trackingNumber.description.toLowerCase().includes('delivered'))) {
        currentTrackingStatus = 'Delivered on ' + formatDateToString(trackingNumber.timeStamp);
        deliveredDate = trackingNumber.timeStamp;
        showDD = true;
    } else {
        currentTrackingStatus = recentEvent ? recentEvent.description : 'Initiated';
    }

    const estimatedDeliveryDate = ((lineItem.estimatedDeliveryDate) ? lineItem.estimatedDeliveryDate : null);
    const estimatedShippedDate = ((lineItem.estimatedShipDate) ? lineItem.estimatedShipDate : null);
    const showESD = (!lineItem.trackDetails && !lineItem.trackingNumbers && !estimatedDeliveryDate && (estimatedShippedDate || 'Pending'));
    const showEDD = (!showETA && trackingNumber && (!recentEvent.description || !events.some(o => o.description.toLowerCase().includes('delivered'))));
    const partnerSelection = (isService && events && events.length && events.find(e => e.description === 'Partner Selection'));
    const partnerSelectionDate = (partnerSelection) ? partnerSelection.timeStamp : null;
    let enRoute = isService && events && events.length && events.find(e => e.description === 'En Route');
    let inProgress = isService && events && events.length && events.find(e => e.description === 'In Progress');
    let finalPrep = isService && events && events.length && events.find(e => e.description === 'Final Testing and Site Cleanup');
    let completed = isService && events && events.length && events.find(e => e.description === 'Completed');
    let workCompleted = isService && events && events.length && events.find(e => e.description === 'Work Completion');
    let arrivalTime = (isService && lineItem.services) ? 
        (lineItem.services.arrivalTime) ? lineItem.services.arrivalTime : 
            (lineItem.services.serviceProviders.length) ? lineItem.services.serviceProviders[0].find(sp => sp.key === 'arrivalTimeCommitment').value : null :
        null;
    let serviceCompletion = (isService && lineItem.services) ? 
        (lineItem.services.serviceProviders.length) ? lineItem.services.serviceProviders[0].find(sp => sp.key === 'endServiceWindow').value : null :
        null;
    const getCurrentStatus = () => {
        if (showDD)
            return 'Delivered';
        else if (sortedHistory[0].statusId === 28 || sortedHistory[0].statusId === 29)
            return 'Submitted for Approval';
        else {
            if (isPart && estimatedShippedDate && sortedHistory.length && (sortedHistory[0].statusId === 25 || sortedHistory[0].statusId === 27))
                return 'Pending Shipment';
            else if ((isPart) && (sortedHistory.length))
                return (sortedHistory[0].statusId === 8 || sortedHistory[0].description.toLowerCase().includes('shipped')) ? 'Shipped' :
                    sortedHistory[0].description;
            else if ((isService) && (sortedHistory.length))
                return (sortedHistory[0].description === 'Completed') ? 'Closed' :
                    (sortedHistory[0].description === 'Final Testing and Site Cleanup') ? 'Final Prep and Cleanup' : sortedHistory[0].description;        
            else if (isRepair && lineItem.repairs) {
                if (lineItem.repairs[0].statusDescription !== 'Initiated')
                    return lineItem.repairs[0].statusDescription;
                if (sortedHistory[0].statusId === 25 || sortedHistory[0].statusId === 27)
                    return 'Purchased';
                return currentTrackingStatus;
            } else return 'Initiated';
        }
    }
    const getMonthDay = (strDate) => {
        let arrDate = strDate.split('-');
        return arrDate[1] + '/' + arrDate[2].substring(0,2);
    }
    const lineItemInfo = {
        sLineItem: lineItem,
        events: events,
        trackDetailResult: trackDetailResult,
        isPart: lineItem.orderTypeId === 1,
        isRepair: lineItem.orderTypeId === 4,
        isService: lineItem.orderTypeId === 17,
        isApprovalRequired,
        isPORequired,
        submittedForApprDate: (isApprovalRequired) ? ((dateQuoted) ? dateQuoted.timeStamp : null) : null,
        awaitingPoDate: (isPORequired) ? isPORequired.timeStamp : null,
        isBackordered: lineItem.isBackordered,
        isPendingShipment: (lineItem.statusId === 25 && lineItem.estimatedShipDate),
        initiatedDate: initiatedDate,
        quoteAvailableDate: ((dateQuoted) ? dateQuoted.timeStamp : (!isService) ? initiatedDate : null),
        orderPlacedDate,
        supplierOrderPlacedDate: ((orderPlacedDate) ? orderPlacedDate.timeStamp : null),
        orderPurchasedDate: ((orderPurchasedDate) ? orderPurchasedDate.timeStamp : null),
        shippedDate: ((isPart && lineItem.dateShipped) ? lineItem.dateShipped :
            (shippedRepair) ? shippedRepair.dateShipped : null),
        deliveredDate,
        estimatedDeliveryDate,
        estimatedShippedDate,
        shippingAddress: (lineItem.shippingAddress),
        currentStatus: getCurrentStatus(),
        repairSentDate: ((sentRepair) ? (sentRepair.dateShipped) ? sentRepair.dateShipped : 'Pending' : null),
        repairCompletionDate: ((isRepair && lineItem.repairs) ? (lineItem.repairs[0].etaDate) ? 
            lineItem.repairs[0].etaDate : 'Pending' : null),
        enRouteDate: (enRoute) ? enRoute.timeStamp : null, 
        inProgressDate: (inProgress) ? inProgress.timeStamp : null, 
        finalPrepDate: (finalPrep) ? finalPrep.timeStamp : null,
        completedDate: (completed) ? completed.timeStamp : null,
        workCompletedDate: (workCompleted) ? workCompleted.timeStamp : null,
        serviceArrivalTime: (arrivalTime) ? `by ` + getMonthDay(arrivalTime) : 'TBD',
        serviceCompletionTime: (serviceCompletion) ? `by ` + getMonthDay(serviceCompletion) : 'TBD',
        showETA,
        trackingNumber,
        trackingNumberPart,
        carrierPart,
        recentEvent,
        currentTrackingStatus,
        showDD,
        showESD,
        showEDD,
        partnerSelectionDate,
    }
    return lineItemInfo;
}
export function getFullDate(date, dateToCompareWith) {
    // check if date month < dateToCompareWith month => next year : same year

    let dateObj = new Date(date);

    let dateToCompareWithObj = new Date(dateToCompareWith);

    if (dateToCompareWithObj.getMonth() < dateObj.getMonth()) {
        // date is in the next year
        dateObj.setFullYear(dateToCompareWithObj.getFullYear() + 1)
    } else {
        dateObj.setFullYear(dateToCompareWithObj.getFullYear());
    }

    let dateWithYearMonthName = months[dateObj.getMonth()];

    return dateWithYearMonthName + ' ' + dateObj.getDate() + ' ' + dateObj.getFullYear() 
        + ' ' + formatAMPM(dateObj);
}
