import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Button} from '@partssourceinc/react-ui-core';
import {Desktop, Phone, PhoneBig, Tablet} from 'responsive';
import AnonymousOrderStatusCardView from 'components/AnonymousOrderStatusCardView';
import AnonymousOrderStatusShippingTrackerView from 'components/AnonymousOrderStatusShippingTrackerView';
import 'less/anonymousOrderStatus.less';

const AnonymousOrderStatus = () => {
    const [lineItemId, setLineItemId] = useState();
    const [lineItemInfo, setLineItemInfo] = useState();
    const isLoggedIn = useSelector(state => state.network.isLoggedIn);
    const sessionStorageAvailable = useSelector(state => state.network.sessionStorageAvailable);
    useEffect(() => {
        let ext = window.location.pathname.split('/')[2]; 
        axios.get(`/ShoppingService/api/v1/orders/status/${ext}`).then((x) => {
            if (!x.data) return location.href = `/login`; 
            if (isLoggedIn) {
                switch (x.data[0].orderTypeId) {
                    case 1:
                        return location.href = `/order/${x.data[0].lineItemId}`; 
                    default: return location.href = `/order/${x.data[0].lineItemId}`;
                }
            }            
            setLineItemId(x.data[0].lineItemId); 
            setLineItemInfo(x.data);
        });
            
    }, []);    
    
    const viewOrderDetails = (ev) => {
        if (!isLoggedIn) {
            if (sessionStorageAvailable)
                sessionStorage.prevUrl = `/order/${lineItemId}`;
            return location.href = '/login';
        }    
    };
      
    let lineItems = lineItemInfo;
    const renderWide = () => 
        (<div className="anonymous-order-status">
            <div className="header-wrapper">
                <h1 className="header-wrapper-heading">Order Status </h1>
                <Button className="primary" onClick={(e) => viewOrderDetails(e)}>
                    View Order Details
                </Button>
            </div>
                    
            <AnonymousOrderStatusCardView
                lineItems={lineItems}
            />
            <AnonymousOrderStatusShippingTrackerView 
                lineItems={lineItems}
            />
            
        </div>);
    const renderNarrow = () => 
        (<div className="anonymous-order-status-mobile">
            <div className="header-wrapper">                    
                <Button className="primary" onClick={(e) => viewOrderDetails(e)}>
                    View Order Details
                </Button>
            </div>
            <AnonymousOrderStatusCardView
                lineItems={lineItems}
            />                
        </div>);
    return (
        <React.Fragment>
            <Desktop>
                {renderWide()}            
            </Desktop>
            <Tablet>
                {renderWide()}  
            </Tablet>
            <PhoneBig>
                {renderNarrow()}  
            </PhoneBig>
            <Phone>
                {renderNarrow()}            
            </Phone>
        </React.Fragment>
    );
}

export default AnonymousOrderStatus;
