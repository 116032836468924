import moment from 'moment';
import React, {useState, useEffect} from 'react';
import {getTrackingNumbersUrlsData, getDayMonth} from 'utility';
import $ from 'jquery';
import 'less/anonymousTrackingPanel.less';
const AnonymousTrackingPanel = (panel) => {
    const {
        show,
        onClose,
        lineItemId,
        lineItemData,
    } = panel;
    const [steps, setSteps] = useState([]);
    const [smallStepLength, setSmallStepLength] = useState(0);
    const [largeStepLength, setLargeStepLength] = useState(0);
    const reduceTrackerHeightScreen = 25 + 100;
    const li = lineItemData;
    useEffect(() => {
        if (show) {
            openTrackingPanel();
        }        
    }, []); 
    const openTrackingPanel = () => {
        $(document.body).addClass('modal-open');
    };
    const closeTrackingPanel = () => {
        $(document.body).removeClass('modal-open');
        onClose();
    };
    useEffect(() => {
        fillStepsData();
    }, []);
    const fillStepsData = () => {
        let filledSteps = [];

        if (!li.isService) {
            filledSteps.push({status: 'Delivered', isLarge: false, dateShownBelow: false, date: li.deliveredDate});
            filledSteps.push({status: 'Shipped', isLarge: true, dateShownBelow: true, date: li.shippedDate});
           
            if (li.isRepair) {
                filledSteps.push({status: 'Repairing', isLarge: true, dateShownBelow: false, date: null});
                filledSteps.push({status: 'Repair being shipped to vendor', isLarge: false, dateShownBelow: false, date: li.repairSentDate});
            }

            if (li.isPart) {                 
                filledSteps.push({status: 'Pending Shipment', isLarge: false, dateShownBelow: false, date: li.estimatedShippedDate});
                if (li.isBackordered) {
                    filledSteps.push({status: 'Backordered', isLarge: false, dateShownBelow: false, date: li.estimatedShippedDate});
                }
                filledSteps.push({status: 'Supplier Order Placed', isLarge: true, dateShownBelow: true, date: li.supplierOrderPlacedDate}); // aka 'Ordered'
            }
        }
        if (li.isService) {
            filledSteps.push({status: 'Work Completion', isLarge: true, dateShownBelow: false, date: li.workCompletedDate});
            filledSteps.push({status: 'Closed', isLarge: false, dateShownBelow: false, date: li.completedDate});
            filledSteps.push({status: 'Final Prep and Cleanup', isLarge: false, dateShownBelow: false, date: li.finalPrepDate});
            filledSteps.push({status: 'In progress', isLarge: true, dateShownBelow: false, date: li.inProgressDate});
            filledSteps.push({status: 'En Route', isLarge: false, dateShownBelow: false, date: li.enRouteDate});
            filledSteps.push({status: 'Dispatched', isLarge: true, dateShownBelow: false, date: li.enRouteDate});
        }
        filledSteps.push({status: 'Purchased', isLarge: false, dateShownBelow: false, date: li.supplierOrderPlacedDate}); // aka 'Closed'
        
        if (li.isPORequired) {
            filledSteps.push({status: 'Awaiting PO', isLarge: false, dateShownBelow: false, date: li.awaitingPoDate});
        }
        
        if (li.isApprovalRequired) {
            filledSteps.push({status: 'Submitted for Approval', isLarge: false, dateShownBelow: false, date: li.submittedForApprDate});
        }

        filledSteps.push({status: 'Quote Available', isLarge: true, dateShownBelow: true, date: li.quoteAvailableDate}); // aka 'Quoted'
        
        if (li.isService) {
            filledSteps.push({status: 'Partner Selection', isLarge: false, dateShownBelow: false, date: li.partnerSelectionDate});
        }

        filledSteps.push({status: 'Initiated', isLarge: true, dateShownBelow: true, date: li.initiatedDate}); // aka 'Requested'
        
        // fill 'isDone' status
        const indexOfFirstFilledStep = (li.currentTrackingStatus.includes('Delivered')) ? 0 : filledSteps.map(e => e.status).indexOf(li.currentStatus);
        
        if (filledSteps.length > 0 && indexOfFirstFilledStep > -1) {
            filledSteps[(filledSteps.length - 1) - indexOfFirstFilledStep].isDone = true;
        } else {
            filledSteps[filledSteps.length - 1].isDone = true;
        }

        for (let i = 0; i < filledSteps.length; i++) {
            filledSteps[i].showOnTracker = true;

            if (indexOfFirstFilledStep <= i) {
                filledSteps[i].isDone = true;
            } else {
                filledSteps[i].isDone = false;
            }
        }
        
        setSteps(filledSteps);
        calcStepsHeight(filledSteps);
    }
    const calcStepsHeight = (filledSteps) => {
        let stepCountMeasure = 0;

        if (filledSteps && filledSteps.length) {
            for (let i = 0; i < filledSteps.length; i++) {
                if (filledSteps[i].isLarge) {
                    stepCountMeasure += 2;
                } else {
                    stepCountMeasure ++;
                }
            }
            let newTrackerHeight = (typeof window !== 'undefined') && window.screen.availHeight - reduceTrackerHeightScreen;

            if (newTrackerHeight) {
                let smallStepCalculated = newTrackerHeight / stepCountMeasure;
                setSmallStepLength(smallStepCalculated);
                setLargeStepLength(smallStepCalculated * 2);
            }
        }
    }
    
    const renderEvent = (step) => {
        let i = steps.indexOf(step);
        return (<li className={`step-progress-item ${step.isDone ? 'is-done' : 'is-not-done'} ${step.isLarge ? 'is-large' : 'is-not-large'} 
            ${step.status === li.currentStatus ? 'is-last' : ''} ${i === (steps.length - 1) ? 'is-bottom' : ''}`}> 
            <span>{(step.date && step.isDone) ? moment(step.date).format('MMMM Do YYYY, h:mm a') : ''}</span>
            <span className="status">{step.status}</span>
        </li>)
    };
    const renderServiceShipBox = () =>
        (<div className="tracking-mobile-shipping-info">
            {!li.enRouteDate && <span className="detailBold">Estimated Service Date </span>}
            {li.enRouteDate && <span className="detailBold">Estimated Completion Date </span>}
            {!li.enRouteDate && <span className="detail">Estimated to be serviced {li.serviceArrivalTime} </span>}
            {li.enRouteDate && <span className="detail">Estimated to be completed {li.serviceCompletionTime} </span>}
        </div>);
    const renderPartShipBox = () =>
        (<div className="tracking-mobile-shipping-info">
            {li.showDD &&
                <span className="detailBold">{li.currentTrackingStatus} </span>}
            {li.showEDD &&    
                <span className="detailBold">Scheduled Delivery Date {getDayMonth(li.estimatedDeliveryDate) || 'Pending'} </span>}
            {li.showETA &&
                    <span className="detailBold">Estimated completion by {getDayMonth(li.repairCompletionDate)}</span>}
            {li.showESD && <span className="detailBold">Scheduled Delivery Date Pending </span>}
            {(li.showESD && li.estimatedShippedDate) &&                                            
                <span className="detail">Estimated to ship by {getDayMonth(li.estimatedShippedDate)} </span>}
            {(li.showESD && !li.estimatedShippedDate) && 
                <span className="detail">Estimated Ship Date pending</span>}
            <span className="detail">{li.shippingAddress} </span>
            {urlsData && urlsData.map((urlData, index) => {
                return <a target="_blank" rel="noreferrer noopener" href={`${urlData.url}`}># {urlData.number} </a>
            })}
        </div>)

    let urlsData = [];

    if (li.trackDetailResult) {
        urlsData = getTrackingNumbersUrlsData(li.trackDetailResult.trackingNumbers, lineItemId);
    }

    return show && <div id="tracking-panel" className="tracking-panel open">
        <div className="overlay" onClick={closeTrackingPanel} />
        <div className="content">
            <div className="close" onClick={closeTrackingPanel}>X</div>
            {(li.isService) ? renderServiceShipBox() : renderPartShipBox()}
            <div className="wrapper">
                <ul className="StepProgress">
                    {steps.map(renderEvent)}
                </ul>
            </div>
        </div>
    </div>;
}

export default AnonymousTrackingPanel;
