import * as React from 'react';
import AnonymousOrderStatusShippingTracker from 'components/AnonymousOrderStatusShippingTracker';
import _ from 'lodash';

import 'less/anonymousOrderStatusShippingTrackerView.less';

const AnonymousOrderStatusShippingTrackerView = ({lineItems}) => {
    // a Shipping Tracker shown for every line item (Part, Repair, Service)
    return (
        <React.Fragment>
            <div className="anonymous-order-status-shipping-tracker-view">
                {_.orderBy(lineItems, ['dateCreated'], ['desc']).map(lineItem => (
                    <AnonymousOrderStatusShippingTracker
                        lineItem={lineItem}
                        key={lineItem.lineItemId}
                    />
                ))}
            </div>
        </React.Fragment>
    );
}

export default AnonymousOrderStatusShippingTrackerView;
