import React, {useRef, useState, useEffect} from 'react';
import {useSelector} from 'react-redux'
import {getTrackingNumbersUrlsData, getTrackingUrl, getDayMonth, getPODLetter} from 'utility';
import {getAosLineItem, getFullDate} from '../aosUtility';
import 'less/anonymousOrderStatusShippingTracker.less';
import {formatDateToString, formatDateToStringDate, formatDateToStringTime} from 'utility';

const AnonymousOrderStatusShippingTracker = ({lineItem}) => {
    const {
        lineItemId,
    } = lineItem;
    const key = lineItem.lineItemId;
    const trackerRef = useRef();
    const reduceTrackerWidthSmallerScreen = 250 + 100;
    const reduceTrackerWidthLargeScreen = 250 + 220 + 160;
    const [steps, setSteps] = useState([]);
    const [smallStepLength, setSmallStepLength] = useState(0);
    const [largeStepLength, setLargeStepLength] = useState(0);
    const li = getAosLineItem(lineItem);
    const userInfo = useSelector(state => state.network.tokenInfo);
    const siteSettings = useSelector(state => state.system.siteSettings);
    
    let urlDataShippingAddressTrackingNumber = [];
    let urlTrackingPart = '';
    if (li.trackDetailResult) {
        urlDataShippingAddressTrackingNumber = getTrackingNumbersUrlsData(li.trackDetailResult.trackingNumbers, lineItemId);
    }
    if (li.trackingNumberPart && li.carrierPart) {
        let carrierNum = (li.carrierPart.toLowerCase().includes('federal')) ? 1 : 2;
        urlTrackingPart = `${getTrackingUrl(li.trackingNumberPart, carrierNum)}`;
    }
    const fillStepsData = () => {
        let filledSteps = [];

        filledSteps.push({status: 'Initiated', isLarge: true, dateShownBelow: true, date: li.initiatedDate}); // aka 'Requested'
        
        if (li.isService) {
            filledSteps.push({status: 'Partner Selection', isLarge: false, dateShownBelow: false, date: li.partnerSelectionDate});
        }

        filledSteps.push({status: 'Quote Available', isLarge: true, dateShownBelow: true, date: li.quoteAvailableDate}); // aka 'Quoted'

        if (li.isApprovalRequired) {
            filledSteps.push({status: 'Submitted for Approval', isLarge: false, dateShownBelow: false, date: li.submittedForApprDate});
        }

        if (li.isPORequired) {
            filledSteps.push({status: 'Awaiting PO', isLarge: false, dateShownBelow: false, date: li.awaitingPoDate});
        }

        filledSteps.push({status: 'Purchased', isLarge: false, dateShownBelow: false, date: li.orderPurchasedDate}); // aka 'Closed'

        if (li.isService) {
            filledSteps.push({status: 'Dispatched', isLarge: true, dateShownBelow: false, date: li.enRouteDate});
            filledSteps.push({status: 'En Route', isLarge: false, dateShownBelow: false, date: li.enRouteDate});
            filledSteps.push({status: 'In progress', isLarge: true, dateShownBelow: false, date: li.inProgressDate});
            filledSteps.push({status: 'Final Prep and Cleanup', isLarge: false, dateShownBelow: false, date: li.finalPrepDate});
            filledSteps.push({status: 'Closed', isLarge: false, dateShownBelow: false, date: li.completedDate});
            filledSteps.push({status: 'Work Completion', isLarge: true, dateShownBelow: false, date: li.workCompletedDate});

        } else {
            let estimatedShippedDateWithYear = (li.estimatedShippedDate) ? getFullDate(li.estimatedShippedDate, li.supplierOrderPlacedDate) : null;

            if (li.isPart) {
                filledSteps.push({status: 'Supplier Order Placed', isLarge: true, dateShownBelow: true, date: li.supplierOrderPlacedDate}); // aka 'Ordered'
                
                if (li.isBackordered) {
                    filledSteps.push({status: 'Backordered', isLarge: false, dateShownBelow: false, date: estimatedShippedDateWithYear});
                }

                filledSteps.push({status: 'Pending Shipment', isLarge: false, dateShownBelow: false, date: estimatedShippedDateWithYear});
            }
            if (li.isRepair) {
                // TODO: change repairSentDate and repairCompletionDate data to the correct one
                // repair sent date -> 07/29 - That's the date it changed status, not the date the package shipped.
                // Usually I guess they would be more the same, but this isn't pulling from the right place.

                // We should look at the tracking to see when that tracking number shipped, not when the status changed.
                
                // Repairs will look first at the printed repair shipping to determine when it shipped to vendor

                let repairSentDateWithYear = getFullDate(li.repairSentDate, li.orderPurchasedDate);
                let repairCompletionDateWithYear = getFullDate(li.repairCompletionDate, repairSentDateWithYear);
                
                filledSteps.push({status: 'Repair being shipped to vendor', isLarge: false, dateShownBelow: false, date: repairSentDateWithYear});
                filledSteps.push({status: 'Repairing', isLarge: true, dateShownBelow: false, date: repairCompletionDateWithYear});
            }

            if (li.isPendingShipment && !li.isRepair && !li.isPart) {
                filledSteps.push({status: 'Pending Shipment', isLarge: false, dateShownBelow: false, date: estimatedShippedDateWithYear});
            }

            filledSteps.push({status: 'Shipped', isLarge: true, dateShownBelow: true, date: li.shippedDate});
            filledSteps.push({status: 'Delivered', isLarge: false, dateShownBelow: false, date: li.deliveredDate});
        }

        const indexOfLastFilledStep = filledSteps.map(e => e.status).indexOf(li.currentStatus);
        
        if (filledSteps.length > 0 && indexOfLastFilledStep > -1) {
            filledSteps[indexOfLastFilledStep].isDone = true;
        } else {
            filledSteps[0].isDone = true;    
        }
        
        for (let i = 0; i < filledSteps.length; i++) {
            filledSteps[i].showOnTracker = true;
            filledSteps[i].showStepTooltip = false;

            if (indexOfLastFilledStep >= i) {
                filledSteps[i].isDone = true;
            } else {
                filledSteps[i].isDone = false;
            }
        }

        setSteps(filledSteps);
        calcStepsWidth(filledSteps);
        handleSmallerResolution(filledSteps);
    }

    const handleResize = () => {
        if (steps.length > 0) {
            handleSmallerResolution(steps);
            calcStepsWidth(steps);
        }
    }

    const handleSmallerResolution = (stepsPassed) => {
        if (window.innerWidth < 1193) {
            if (trackerRef) {
                trackerRef.current.style.width = window.innerWidth - reduceTrackerWidthSmallerScreen;
            }

            let stepsForSmallerScreen = _.cloneDeep(stepsPassed);
            
            for (let i = stepsForSmallerScreen.length - 1; i >= 0; i--) {
                if (stepsForSmallerScreen[i].isLarge) {
                    stepsForSmallerScreen[i].showOnTracker = true;

                    // do not show heading to all other steps
                    for (let j = 0; j < i; j++) {
                        stepsForSmallerScreen[j].showOnTracker = false;
                    }
                    
                    break;
                }
            }
            
            setSteps(stepsForSmallerScreen);
        } else {
            let stepsForSmallerScreen = _.cloneDeep(stepsPassed);

            for (let i = 0; i < stepsForSmallerScreen.length; i++) {
                stepsForSmallerScreen[i].showOnTracker = true;
            }
            
            setSteps(stepsForSmallerScreen);
        }
    }

    const calcStepsWidth = (filledSteps) => {
        let stepCountMeasure = 0;

        if (filledSteps && filledSteps.length) {
            for (let i = 0; i < filledSteps.length; i++) {
                if (filledSteps[i].isLarge) {
                    stepCountMeasure += 2;
                } else {
                    stepCountMeasure ++;
                }
            }

            let newTrackerWidth = 0;
            if (window.innerWidth > 1600) { // handle bigger screens
                newTrackerWidth = 1600 - reduceTrackerWidthLargeScreen;

            } else {
                newTrackerWidth = window.innerWidth - reduceTrackerWidthLargeScreen;
            }

            if (window.innerWidth < 1148) {
                newTrackerWidth += 20;
            }
    
            if (newTrackerWidth) {
                let smallStepCalculated = newTrackerWidth / stepCountMeasure;
                setSmallStepLength(smallStepCalculated);
                setLargeStepLength(smallStepCalculated * 2);
            }
        }
    }

    useEffect(() => {
        if (trackerRef) {
            trackerRef.current.style.width = window.innerWidth - reduceTrackerWidthLargeScreen;
        }

        fillStepsData();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [steps, smallStepLength, largeStepLength]);

    const renderStep = (step) => {
        // each step should have a status, isLarge, dateShownBelow, date, description (for the popup), isDone
        let i = steps.indexOf(step);
        return (<li 
            key={`${key}_${i}`} 
            data-key={`${i}`} 
            style={{width: step.isLarge ? largeStepLength : smallStepLength, cursor: 'pointer'}} 
            className={`step-progress-item ${(step.isDone || step.status === li.currentStatus) ? 'is-done' : 'is-not-done'} ${step.isLarge ? 'is-large' : 'is-not-large'} 
            ${(step.status === li.currentStatus) && i < (steps.length - 1) ? 'is-last' : ''} ${(step.status === li.currentStatus) && i === (steps.length - 1) ? 'is-last-done' : ''}`} 
            onClick={e => onStepClick(e)} />)
    }
    const renderStepHeadings = (step) => {
        return (<div key={`${key}_${steps.indexOf(step)}`} style={{width: step.isLarge ? largeStepLength : smallStepLength, display: step.showOnTracker ? 'inline' : 'none'}} className={`step-heading ${step.isLarge ? 'is-large' : 'is-not-large'}`}>
            {step.isLarge ? 
                <span className="status">{step.status}</span> : null}
        </div>)
    }

    const renderStepDates = (step) => {
        return (<div key={`${key}_${steps.indexOf(step)}`} style={{width: step.isLarge ? largeStepLength : smallStepLength, display: step.showOnTracker ? 'inline' : 'none'}} className="step-date">
            {step.dateShownBelow ? (step.date ? formatDateToString(step.date) : null) : null}
        </div>)
    }

    const renderStepTooltips = (step) => {
        return (<div style={{float: 'left', display: 'inline', visibility: step.showStepTooltip ? 'visible' : 'hidden', width: step.isLarge ? largeStepLength : smallStepLength}} >
            <div 
                className="tooltip-wrapper" 
                key={`${key}_${steps.indexOf(step)}`}
                id={`step-tooltip-${steps.indexOf(step)}`}>
                <div style={{fontWeight: 'bold'}}>{step.status}</div>
                <div style={{fontWeight: 'bold'}}>{step.date && step.date !== 'Invalid date' ? formatDateToStringDate(step.date) : null}</div>
                <div>{step.date && step.date !== 'Invalid date' ? formatDateToStringTime(step.date) : null}</div>
            </div>
        </div>)
    }

    const renderServiceShipBox = () =>
        (<div className="scheduled-delivery-wrapper">
            {!li.enRouteDate && <div style={{fontWeight: 'bold'}}>Estimated Service Date </div>}
            {li.enRouteDate && <div style={{fontWeight: 'bold'}}>Estimated Completion Date </div>}
            {!li.enRouteDate && <div>Estimated to be serviced {li.serviceArrivalTime}  </div>}
            {li.enRouteDate && <div>Estimated to be completed {li.serviceCompletionTime}  </div>}
        </div>);
    
    const renderPartShipBox = () =>
        (<div className="scheduled-delivery-wrapper">
            {li.showDD &&    
                <div style={{fontWeight: 'bold'}}>{li.currentTrackingStatus} </div>}
            {li.showEDD &&    
                <div style={{fontWeight: 'bold'}}>Scheduled Delivery Date {getDayMonth(li.estimatedDeliveryDate) || 'Date Pending'} </div>}
            {li.showETA &&
                <div style={{fontWeight: 'bold'}}>Estimated completion by {getDayMonth(li.repairCompletionDate)}</div>}
            {li.showESD && <div style={{fontWeight: 'bold'}}>Scheduled Delivery Date Pending </div>}
            {(li.showESD && li.estimatedShippedDate && !li.shippedDate) &&
                <div>Estimated to ship by {getDayMonth(li.estimatedShippedDate)} </div>}
            {(li.showESD && !li.estimatedShippedDate && !li.shippedDate) && 
                <div>Estimated Ship Date pending</div>}
            <div>{li.shippingAddress}</div>
            <div>
                {urlDataShippingAddressTrackingNumber && urlDataShippingAddressTrackingNumber.map((urlData, index) => {
                    return <a target="_blank" rel="noreferrer noopener" href={`${urlData.url}`}># {urlData.number} </a>
                })}
            </div>
            {
                li.sLineItem.podLetter && siteSettings.proofOfDeliveryLinks && <div className="download-pod" onClick={() => {
                    getPODLetter(lineItemId, userInfo, "AnonymousOrderStatusShippingTracker")
                }}><i className="fa fa-file-text-o" style={{paddingRight: '5px'}} />Proof of Delivery</div>
            }
        </div>);

    const onStepClick = (e) => {
        let keyIndex = parseInt(e.target.getAttribute('data-key'));
        let stepsUpdated = [... steps];

        if (stepsUpdated[keyIndex].showStepTooltip) {
            stepsUpdated[keyIndex].showStepTooltip = false;
        } else {
            stepsUpdated[keyIndex].showStepTooltip = true;

            // hide all other step tooltips - show only the last one that was opened
            for (let i = 0; i < stepsUpdated.length; i++) {
                if (i !== keyIndex) {
                    stepsUpdated[i].showStepTooltip = false;
                }
            }
        }

        setSteps(stepsUpdated);
    }

    return (
        <div ref={trackerRef} key={key} className="shipping-tracker-view">
            <div className="timeline-wrapper">
                <div className="steps-headings-wrapper">
                    {steps && steps.map(e => renderStepHeadings(e))}
                </div>
                <ul className="step-progress">
                    {steps && steps.map(e => renderStep(e))}
                </ul>
                <div className="steps-dates-wrapper">
                    {steps && steps.map(e => renderStepDates(e))}
                </div>
                <div className="steps-tooltip-wrapper">
                    {steps && steps.map(e => renderStepTooltips(e))}
                </div>
            </div>
            {(li.isService) ? renderServiceShipBox() : renderPartShipBox()}
        </div>
    );
}

export default AnonymousOrderStatusShippingTracker;
