import * as React from 'react';
import AnonymousOrderStatusCard from 'components/AnonymousOrderStatusCard';
import _ from 'lodash';

import 'less/anonymousOrderStatusCardView.less';

const AnonymousOrderStatusCardView = ({lineItems}) => {
    // a card shown for every line item (Part, Repair, Service)
    return (
        <React.Fragment>
            <div className="anonymous-order-status-card-view">
                {_.orderBy(lineItems, ['dateCreated'], ['desc']).map(lineItem => (
                    <AnonymousOrderStatusCard
                        lineItem={lineItem}
                        key={lineItem.lineItemId}
                    />
                ))}
            </div>
        </React.Fragment>
    );
}

export default AnonymousOrderStatusCardView;
